// AuthSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../../app/axiosInstance";
import { handleApiError } from "../../common/utils/ErrorHandler";

const customerUrl = '/api/customer';
const authUrl = `${customerUrl}/auth`;

// initialize customerToken from local storage
const customer = localStorage.getItem("customer") !== 'undefined'
  ? JSON.parse(localStorage.getItem("customer"))
  : null;

const customerToken = localStorage.getItem("customerToken") !== 'undefined'
  ? localStorage.getItem("customerToken")
  : null;


export const removeCustomerData = () => {
  localStorage.removeItem('customerToken');
  localStorage.removeItem('customer');
};

export const prepareHeaders = (state, addToken = true, options = {}) => {
  let headers = {};
  if (state?.language?.language) {
    headers['locale'] = state.language.language;
  }
  if (addToken && state?.auth?.customerToken) {
    headers['Authorization'] = `Bearer ${state.auth.customerToken}`;
  }
  if (typeof options === 'object' && !Array.isArray(options) && options !== null) {
    headers = { ...options, ...headers };
  }
  return headers
}

// Async thunk for login
export const login = createAsyncThunk(
  "auth/login",
  async (credentials, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.post(
        `${authUrl}/login-customer`,
        credentials,
        {
          headers: prepareHeaders(state, false),
        }
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch, false);
    }
  }
);

export const logout = createAsyncThunk(
  "auth/logout",
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.post(
        `${authUrl}/logout`, {},
        {
          headers: prepareHeaders(state),
        }
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

// Async thunk for register
export const register = createAsyncThunk(
  "auth/register",
  async (data, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.post(
        `${authUrl}/register`,
        data,
        {
          headers: prepareHeaders(state, false, {
            'Content-Type': 'multipart/form-data',
          }),
        }
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch, false);
    }
  }
);

// Async thunk for forgetPassword
export const forgetPassword = createAsyncThunk(
  "auth/forgetPassword",
  async (credentials, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.post(
        `${authUrl}/forgot-password`,
        credentials,
        {
          headers: prepareHeaders(state, false),
        }
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch, false);
    }
  }
);

// Async thunk for fetching profile
export const fetchProfile = createAsyncThunk('auth/fetchProfile', async (_, { getState, rejectWithValue, dispatch }) => {
  try {
    const state = getState();
    const response = await axiosInstance.get(`${authUrl}/user`, {
      headers: prepareHeaders(state),
    });
    return response.data;
  } catch (error) {
    return handleApiError(error, rejectWithValue, dispatch);
  }
});

// Async thunk for updating profile
export const updateProfile = createAsyncThunk('auth/updateProfile', async (profileData, { getState, rejectWithValue, dispatch }) => {
  try {
    const state = getState();
    const response = await axiosInstance.post(`${customerUrl}/update-profile`, profileData, {
      headers: prepareHeaders(state),
    });
    return response.data;
  } catch (error) {
    return handleApiError(error, rejectWithValue, dispatch);
  }
});

// Async thunk for changing password
export const changePassword = createAsyncThunk(
  'auth/changePassword',
  async (passwordData, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.post(`${customerUrl}/change-password`, passwordData, {
        headers: prepareHeaders(state),
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const updateProfileImage = createAsyncThunk(
  'auth/updateProfileImage',
  async (data, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.post(`${customerUrl}/profile-picture`, data, {
        headers: prepareHeaders(state, true, {
          'Content-Type': 'multipart/form-data',
        }),
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

const AuthSlice = createSlice({
  name: "auth",
  initialState: {
    isAuthenticated: customerToken ? true : false,
    customerToken,
    customer,
    loading: false,
    error: null,
    success: null
  },
  reducers: {
    clearCustomerState: (state) => {
      state.isAuthenticated = false;
      state.customerToken = null;
      state.customer = null;
    },
    clearSuccess: (state) => {
      state.success = null;
    }
  },
  extraReducers: (builder) => {
    builder
      // login case
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isAuthenticated = true;
        let data = action.payload.payload;
        // store customer's token in local storage
        localStorage.setItem("customerToken", data.access_token);
        localStorage.setItem("customer", JSON.stringify(data.user));
        state.customer = data.user;
        state.customerToken = data.access_token;
        state.loading = false;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;

      })
      // logout case
      .addCase(logout.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(logout.fulfilled, (state) => {
        localStorage.removeItem("customerToken");
        localStorage.removeItem("customer");
        state.isAuthenticated = false;
        state.customer = null;
        state.role = null;
        state.customerToken = null;
        state.loading = false;
      })
      .addCase(logout.rejected, (state, action) => {
        state.isAuthenticated = false;
        state.customer = null;
        state.customerToken = null;
        state.loading = false;
        state.error = action.payload;
      })
      // register case
      .addCase(register.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.isAuthenticated = false;
        state.success = action.payload.message;
        state.loading = false;
      })
      .addCase(register.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // forgetPassword case
      .addCase(forgetPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(forgetPassword.fulfilled, (state, action) => {
        state.isAuthenticated = false;
        state.success = action.payload.message;
        state.loading = false;
      })
      .addCase(forgetPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Fetch profile cases
      .addCase(fetchProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProfile.fulfilled, (state, action) => {
        let data = action?.payload?.payload;
        // store customer's data in local storage
        localStorage.setItem("customer", JSON.stringify(data));
        state.customer = data;
        state.loading = false;
      })
      .addCase(fetchProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Update profile cases
      .addCase(updateProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.customer = action?.payload?.payload;
        localStorage.setItem("customer", JSON.stringify(state.customer));
        state.loading = false;
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Change password cases
      .addCase(changePassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(changePassword.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Update profile image cases
      .addCase(updateProfileImage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateProfileImage.fulfilled, (state, action) => {
        state.customer = action.payload.payload;
        localStorage.setItem("customer", JSON.stringify(state.customer));
        state.loading = false;
      })
      .addCase(updateProfileImage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearCustomerState, clearSuccess } = AuthSlice.actions;
export default AuthSlice.reducer;