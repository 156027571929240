import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../../app/axiosInstance";
import { handleApiError } from "../../common/utils/ErrorHandler";
import { prepareHeaders } from "../../auth/slice/AuthSlice";
import { PER_PAGE_NOTIFICATION, TOP_BAR_NOTIFICATION } from "../../../constants/pagination";

const notificationUrl = `/api/customer/notification`;

export const fetchNotifications = createAsyncThunk(
  'notifications/fetchNotifications',
  async ({ page = 1, perPage = PER_PAGE_NOTIFICATION }, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.get(`${notificationUrl}/index`, {
        params: { page, per_page: perPage },
        headers: prepareHeaders(state)
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const fetchTopBarNotifications = createAsyncThunk(
  'notifications/fetchTopBarNotifications',
  async ({ perPage = PER_PAGE_NOTIFICATION }, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.get(`${notificationUrl}/index`, {
        params: { page: 1, per_page: perPage },
        headers: prepareHeaders(state)
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const markAsRead = createAsyncThunk(
  'notifications/markAsRead',
  async (ids, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.post(`${notificationUrl}/marked-as-read`, {
        notification_ids: ids,
      }, {
        headers: prepareHeaders(state)
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

const NotificationSlice = createSlice({
  name: "notification",
  initialState: {
    notifications: [],
    topNotifications: [],
    loading: false,
    isTopBarNotificationLoading: false,
    total: 0,
    unreadCount: 0,
    pageCount: 0,
    currentPage: 0,
    perPage: PER_PAGE_NOTIFICATION,
    error: null,
    success: null
  },
  reducers: {
    setPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setPerPage: (state, action) => {
      state.perPage = action.payload;
      state.currentPage = 0;
    },
    clearError: (state) => {
      state.error = '';
    },
    clearSuccess: (state) => {
      state.success = null;
    }
  },
  extraReducers: (builder) => {
    builder
      // fetch notifications
      .addCase(fetchNotifications.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.loading = false;
        let data = action.payload?.payload;
        let notifications = data?.notifications;
        state.notifications = notifications?.data;
        state.total = notifications?.total;
        state.pageCount = notifications?.last_page;
        state.currentPage = notifications?.current_page ? notifications.current_page - 1 : 0;
        state.unreadCount = data?.unread_count;
      })
      .addCase(fetchNotifications.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // fetch notifications for topbar
      .addCase(fetchTopBarNotifications.pending, (state) => {
        state.isTopBarNotificationLoading = true;
        state.error = '';
      })
      .addCase(fetchTopBarNotifications.fulfilled, (state, action) => {
        state.isTopBarNotificationLoading = false;
        let data = action.payload?.payload;
        let notifications = data?.notifications
        state.topNotifications = notifications?.data;
        state.total = notifications?.total;
        state.unreadCount = data?.unread_count;
      })
      .addCase(fetchTopBarNotifications.rejected, (state, action) => {
        state.isTopBarNotificationLoading = false;
        state.error = action.payload;
      })
      // mark notifications as read
      .addCase(markAsRead.pending, (state) => {
        state.isTopBarNotificationLoading = true;
        state.error = '';
      })
      .addCase(markAsRead.fulfilled, (state, action) => {
        state.isTopBarNotificationLoading = false;
        let data = action.payload?.payload;
        let notifications = data?.notifications
        state.notifications = notifications?.data;
        state.topNotifications = notifications?.data ? notifications.data.slice(0, TOP_BAR_NOTIFICATION - 1) : [];
        state.total = notifications?.total;
        state.unreadCount = data?.unread_count;
      })
      .addCase(markAsRead.rejected, (state, action) => {
        state.isTopBarNotificationLoading = false;
        state.error = action.payload;
      });
  },
});

export const { setPage, setPerPage, clearError, clearSuccess } = NotificationSlice.actions;
export default NotificationSlice.reducer;