import { clearCustomerState, removeCustomerData } from "../../auth/slice/AuthSlice";

export const handleApiError = (error, rejectWithValue, dispatch, removeCustomerDataIfUnauthenticated = true) => {
  if (error.response) {
    if (error.response.status === 401) {
      if (removeCustomerDataIfUnauthenticated) {
        removeCustomerData();
        dispatch(clearCustomerState());
      }
      return rejectWithValue(error.response.data);
    } else if (error.response.status === 422) {
      return rejectWithValue(error.response.data);
    } else if (error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    }
  }
  return rejectWithValue(error.message);
};
