import { createSlice } from '@reduxjs/toolkit';

const VisibilitySlice = createSlice({
  name: 'visibility',
  initialState: {
    visible: false, // Initial state
  },
  reducers: {
    toggleVisible(state) {
      state.visible = !state.visible; // Toggle the visibility
    },
  },
});

export const { toggleVisible } = VisibilitySlice.actions;

export default VisibilitySlice.reducer;