// axiosInstance.js
import axios from "axios";

import { API_BASE_URL } from "../constants/app";

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  // timeout: 10000, // Set a timeout for the requests
});

export default axiosInstance;
