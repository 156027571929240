const CURRENCY_USD = 'usd';
const CURRENCY_JPY = 'jpy';

const getCurrencySymbol = (key) => {
    const symbolData = {
        "usd": '$',
        "jpy": '¥',
    }
    return key ? (symbolData[key] ? symbolData[key] : '') : '';
};

export { CURRENCY_JPY, CURRENCY_USD, getCurrencySymbol };