import { configureStore } from "@reduxjs/toolkit";

import AuthSlice from "../modules/auth/slice/AuthSlice";
import BillingSlice from "../modules/billing/slice/BillingSlice";
import HeaderSlice from "../modules/common/slice/HeaderSlice";
import LanguageSlice from "../modules/common/slice/LanguageSlice";
import NotificationSlice from "../modules/notification/slice/NotificationSlice";
import PageSlice from "../modules/pages/slice/PageSlice";
import QuestionnnaireSlice from "../modules/questionnaire/slice/QuestionnnaireSlice";
import SkuSlice from "../modules/sku/slice/SkuSlice";
import VisibilitySlice from "../modules/containers/slice/VisibilitySlice";

const combinedReducer = {
    auth: AuthSlice,
    bill: BillingSlice,
    header: HeaderSlice,
    language: LanguageSlice,
    notification: NotificationSlice,
    page: PageSlice,
    questionnaire: QuestionnnaireSlice,
    sku: SkuSlice,
    visibility: VisibilitySlice
};

export default configureStore({
    reducer: combinedReducer,
});
